import { FC } from "react";

import { Button, Checkbox, Divider, Flex } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

interface UsagesFilterProps {
  dropdownProps: FilterDropdownProps;
}

const UsagesFilter: FC<UsagesFilterProps> = ({ dropdownProps }) => {
  return (
    <Flex vertical gap={8} style={{ padding: 8, width: 240 }}>
      <Flex vertical style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={(values) => {
            dropdownProps.setSelectedKeys(values);
          }}
          value={dropdownProps.selectedKeys}
        >
          <Checkbox style={{ width: "100%", marginBottom: 4 }} value="_empty">
            Vide (Sans valeur)
          </Checkbox>
          <Checkbox
            style={{ width: "100%", marginBottom: 4 }}
            value="Appartement"
          >
            Appartement
          </Checkbox>
          <Checkbox
            style={{ width: "100%", marginBottom: 4 }}
            value="Habitation à un seul logement"
          >
            Habitation à un seul logement
          </Checkbox>
          <Checkbox
            style={{ width: "100%", marginBottom: 4 }}
            value="Habitation à plusieurs logements"
          >
            Habitation à plusieurs logements
          </Checkbox>
          <Checkbox
            style={{ width: "100%", marginBottom: 4 }}
            value="Local commercial"
          >
            Local commercial
          </Checkbox>
          <Checkbox style={{ width: "100%", marginBottom: 4 }} value="Terrain">
            Terrain
          </Checkbox>
          <Checkbox style={{ width: "100%", marginBottom: 4 }} value="Garage">
            Garage
          </Checkbox>
          <Checkbox style={{ width: "100%", marginBottom: 4 }} value="Parking">
            Parking
          </Checkbox>
	  <Checkbox style={{ width: "100%", marginBottom: 4 }} value="Bureau">
	    Bureau
	  </Checkbox>
        </Checkbox.Group>
      </Flex>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Flex justify="space-between">
        <Button
          onClick={() => dropdownProps.setSelectedKeys([])}
          type="link"
          size="small"
          disabled={dropdownProps.selectedKeys.length === 0}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => dropdownProps.confirm()}
          type="primary"
          size="small"
        >
          OK
        </Button>
      </Flex>
    </Flex>
  );
};

export default UsagesFilter;

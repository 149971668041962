import { FC, Key } from "react";

import { Flex } from "antd";

import DuplicateTransactions from "./DuplicateTransactions";
import RefreshAddress from "./RefreshAddress";
import Statistiques from "./Statistiques";

const TransactionFaoActions: FC<{ selectedRowKeys: Key[], filters: Record<string, string[]>}> = ({
  selectedRowKeys,
  filters
}) => {
  const ids = selectedRowKeys.map((k) => k.toString());

  return (
    <Flex gap={16}>
      <DuplicateTransactions ids={ids} />
      <RefreshAddress ids={ids} />
      <Statistiques filters={filters} />
    </Flex>
  );
};
export default TransactionFaoActions;

import { DatabaseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Flex, Layout, Typography } from "antd";
import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Collection } from "./services/collections";

const { Header, Content } = Layout;

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const { collections, user } = useLoaderData() as {
    collections: Collection[];
    user: any;
  };
  const defaultSort: {[key:string]: string} = {
    transactions_fao: "?sort=-Requete"
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f5f5f5",
          alignItems: "center",
        }}
      >
        <Flex gap={24}>
          <Link style={{ display: "flex", alignItems: "center" }} to={"/"}>
            <img height={54} src="/logo.svg" alt="Melcarne" />
          </Link>
          {location.pathname !== "/" ? (
            <span>
              <Link to="/">
                <Typography.Text
                  strong
                  style={{
                    fontSize: 18,
                    textTransform: "uppercase",
                    letterSpacing: 2.5,
                  }}
                >
                  Directus Frontend
                </Typography.Text>
              </Link>
              {collectionId && (
                <Typography.Text
                  strong
                  style={{
                    fontSize: 16,
                    textTransform: "uppercase",
                    letterSpacing: 2.5,
                  }}
                >
                  &nbsp;&nbsp;/&nbsp;&nbsp;{collectionId}
                </Typography.Text>
              )}
            </span>
          ) : (
            <Typography.Title
              level={2}
              style={{ marginBottom: 0, lineHeight: 2 }}
            >{`Bonjour ${user.first_name}!`}</Typography.Title>
          )}
        </Flex>

        <Flex gap={16} align="center">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "melcarnemap",
                  label: (
                    <Link key="melcarnemap" to="https://melcarnemap.digipulse.ch" target="_blank">
                      Melcarne Map
                    </Link>
                  ),
                },
                ...collections.map(({ name }: Collection) => ({
                key: name,
                label: (
                  <Link key={name} to={`collections/${name}${defaultSort[name] || ''}`}>
                    {name}
                  </Link>
                ),
              }))],
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <Button icon={<DatabaseOutlined />}>Collections</Button>
          </Dropdown>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                { key: 1, label: `${user.first_name} ${user.last_name}` },
                {
                  key: 2,
                  label: (
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        localStorage.removeItem("x-token");
                        navigate("/login");
                      }}
                    >
                      Se déconnecter
                    </Button>
                  ),
                },
              ],
            }}
            placement="bottomRight"
          >
            <Avatar
              style={{ cursor: "pointer" }}
              size="large"
              src={`https://melcarnedirectus2.digipulse.ch/assets/${
                user.avatar
              }?key=system-medium-cover&access_token=${
                JSON.parse(localStorage.getItem("x-token")!).access_token
              }`}
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Flex>
      </Header>
      <Content
        style={{
          height: "calc(100% - 64px)",
          backgroundColor: "#ffffff",
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AppLayout;
